<template>
  <div class="p4b__parent">
    <div
      v-if="showMessage"
      class="container"
    >
      <div class="p4b__toc-logo">
        <img
          :src="isMobile() ? logoMobImg : logoImg"
          alt="Logo"
        >
      </div>
      <div class="p4b__onboarding-method-heading">
        <h4>Onboarding Method</h4>
        <p>Please select your preferred method to complete the onboarding form.</p>
      </div>
      <b-card
        class="p4b__onboarding-method-card cursor-pointer"
      >
        <b-card-text>
          <b-media
            left-align
            vertical-align="center"
            class="p4b__onboarding-method-media"
          >
            <template #aside>
              <b-avatar
                size="60px"
              >
                <b-img
                  :src="frame"
                  width="20"
                />
              </b-avatar>
            </template>
            <h5 class="mt-0">
              Retrieve from <b-img
                :src="singPassLogo"
                width="88"
              />
            </h5>
            <p>
              The faster way. The following information will be retrieved:
            </p>
            <b-badge variant="light-primary">
              Takes about 2 mins
            </b-badge>
          </b-media>
        </b-card-text>
      </b-card>
      <b-card
        class="p4b__onboarding-method-card cursor-pointer"
        @click="onboardingForm"
      >
        <b-card-text>
          <b-media
            left-align
            vertical-align="center"
            class="p4b__onboarding-method-media"
          >
            <template #aside>
              <b-avatar
                size="60px"
              >
                <b-img
                  :src="closeAffordable"
                  width="20"
                />
              </b-avatar>
            </template>
            <h5 class="mt-0">
              Fill in a form
            </h5>
            <p>
              Enter your details manually to complete onboarding.
            </p>
            <b-badge variant="light-primary">
              Takes about 5 mins
            </b-badge>
          </b-media>
        </b-card-text>
      </b-card>
    </div>
    <div v-if="expiredLink">
      <div class="container">
        <div class="p4b__onboarding-form-content">
          <div class="p4b__toc-logo">
            <img
              :src="isMobile() ? logoWhiteMobImg : logoWhiteLargeImg"
              alt="Logo"
            >
          </div>
          <div class="p4b__onboarding-form-link-heading">
            <feather-icon
              icon="LinkIcon"
              size="80"
            />
            <h4>This invite has expired</h4>
            <p>Your onboarding link has expired. Please request a new link from your upline to continue the process.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // BButton,
  BCard, BCardText, BAvatar, BImg, BMedia, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BButton,
    BCard,
    BCardText,
    BAvatar,
    BImg,
    BMedia,
    BBadge,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-blue.png'),
      // eslint-disable-next-line global-require
      logoMobImg: require('@/assets/images/frontend/p4b/logo-blue-mob.png'),
      // eslint-disable-next-line global-require
      frame: require('@/assets/images/frontend/p4b/icons/frame.png'),
      // eslint-disable-next-line global-require
      closeAffordable: require('@/assets/images/frontend/p4b/icons/close-affordable.png'),
      // eslint-disable-next-line global-require
      singPassLogo: require('@/assets/images/frontend/p4b/singpass-logo.png'),
      // eslint-disable-next-line global-require
      logoWhiteLargeImg: require('@/assets/images/frontend/p4b/logo-white-large.png'),
      // eslint-disable-next-line global-require
      logoWhiteMobImg: require('@/assets/images/frontend/p4b/logo-white-mob.png'),
      showMessage: false,
      expiredLink: false,
      tokenDetail: {},
    }
  },

  beforeMount() {
    document.body.classList.add('p4b__bg')
    const { token } = this.$route.params
    if (token) {
      this.$http.get('front-end/agent/onboarding/validate/token', { params: { token } })
        .then(response => {
          if (response.data.success) {
            if (response.data.validToken) {
              this.showMessage = true
              this.tokenDetail = response.data.token || {}
            } else {
              document.body.classList.remove('p4b__bg')
              document.body.classList.add('p4b__bg-black')
              this.expiredLink = true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },

  methods: {
    onboardingForm() {
      this.$router.push({ name: 'onboard-form-frontend', params: { token: this.$route.params.token } })
    },
  },
}
</script>
<!-- <style>
body{
  background-color: #F7F5F5 !important;
}
</style> -->
